// all sort types (can be added another if there are more)

export const SORT_TYPES = {
    created: '-created_at',
    updated: '-updated_at',
    alphabetical: 'name',
    alphabeticalByTitle: 'title',
    alphabeticalByLabel: 'label',
    lastRun: '-last_run_at',
    subject: 'subject',
};

export const SORT_TYPES_LABELS = {
    created: 'Most Recently Created',
    updated: 'Most Recently Updated',
    alphabetical: 'Alphabetical',
    alphabeticalByTitle: 'Alphabetical',
    alphabeticalByLabel: 'Alphabetical',
    lastRun: 'Most Recently Run',
    newest: 'Newest',
};

// sort options array for JobsPage
export const jobsSortOptions = [
    {
        text: SORT_TYPES_LABELS.alphabetical,
        value: SORT_TYPES.alphabetical,
    },
    {
        text: SORT_TYPES_LABELS.created,
        value: SORT_TYPES.created,
    },
    {
        text: SORT_TYPES_LABELS.updated,
        value: SORT_TYPES.updated,
    },
];

// sort options array for TemplatesLibraryPage
export const templateLibrarySortOptions = [
    {
        text: SORT_TYPES_LABELS.alphabetical,
        value: SORT_TYPES.alphabetical,
    },
    {
        text: SORT_TYPES_LABELS.created,
        value: SORT_TYPES.created,
    },
];

// sort options array for GoalTemplateDatasetsAndTestRunsPage (datasets tab)
export const datasetSortOptions = [
    {
        text: SORT_TYPES_LABELS.alphabetical,
        value: SORT_TYPES.alphabetical,
    },
    {
        text: SORT_TYPES_LABELS.created,
        value: SORT_TYPES.created,
    },
    {
        text: SORT_TYPES_LABELS.lastRun,
        value: SORT_TYPES.lastRun,
    },
];

// sort options array for GoalTemplateDatasetsAndTestRunsPage (test runs tab)
export const testRunsSortOptions = [
    {
        text: SORT_TYPES_LABELS.created,
        value: SORT_TYPES.created,
    },
    {
        text: SORT_TYPES_LABELS.alphabetical,
        value: SORT_TYPES.alphabetical,
    },
];

// sort options array for Inbox Page
export const inboxSortOptions = [
    {
        text: SORT_TYPES_LABELS.newest,
        value: SORT_TYPES.created,
    },
    {
        text: SORT_TYPES_LABELS.alphabetical,
        value: SORT_TYPES.subject,
    },
];

// sort options array for Process Page
export const processPageSortOptions = [
    {
        text: SORT_TYPES_LABELS.alphabetical,
        value: SORT_TYPES.alphabetical,
    },
    {
        text: SORT_TYPES_LABELS.created,
        value: SORT_TYPES.created,
    },
];

// sort options array for Base Detail Page
export const baseEntriesSortOptions = [
    {
        text: SORT_TYPES_LABELS.alphabetical,
        value: SORT_TYPES.alphabeticalByLabel,
    },
    {
        text: SORT_TYPES_LABELS.created,
        value: SORT_TYPES.created,
    },
    {
        text: SORT_TYPES_LABELS.updated,
        value: SORT_TYPES.updated,
    },
];

// here can be added sortOptions array for other pages
