import PlayProcessIcon from '../design-system/Icons/PlayProcessIcon';
import FileTextLineIcon from '../design-system/Icons/FileTextLineIcon';
import ArchiveLineIcon from '../design-system/Icons/ArchiveLineIcon';

export const CHAT_ROLE = {
    user: 'user',
    assistant: 'assistant',
};

export const HISTORY_GROUP_LABEL = {
    today: 'Today',
    yesterday: 'Yesterday',
    week: 'Previous 7 days',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
};

export const HISTORY_TAB = {
    chats: 'chats',
    jobs: 'jobs',
};

export const TOOL_TYPE = {
    query_playbooks: 'query_playbooks',
    add_document: 'add_document',
    query_knowledge: 'query_knowledge',
};

export const TOOL_ICON = {
    [TOOL_TYPE.query_playbooks]: PlayProcessIcon,
    [TOOL_TYPE.add_document]: FileTextLineIcon,
    [TOOL_TYPE.query_knowledge]: ArchiveLineIcon,
};

// If a color is not specified here, `icon_color` will be used as the default
export const TOOL_ICON_COLOR = {
    [TOOL_TYPE.query_playbooks]: '#000000',
    [TOOL_TYPE.query_knowledge]: '#1F2125',
};

export const TOOL_BADGE_OPACITY = {
    [TOOL_TYPE.query_playbooks]: 100,
    [TOOL_TYPE.query_knowledge]: 100,
    [TOOL_TYPE.add_document]: 20,
};

export const SOURCE_LINK_TYPE = {
    playbook: 'playbook',
    document: 'document',
    knowledge: 'knowledge',
};

export const SOURCE_LINK_PREFIX = {
    [SOURCE_LINK_TYPE.playbook]: '/playbook/',
    [SOURCE_LINK_TYPE.document]: '/doc/',
    [SOURCE_LINK_TYPE.knowledge]: '/base/',
};
