import React, { memo } from 'react';

import { applyOpacityToHex } from '../../../helpers/applyOpacityToHex';
import {
    SOURCE_LINK_PREFIX,
    SOURCE_LINK_TYPE,
    TOOL_BADGE_OPACITY,
} from '../../../constants/assistant';

import { Badge } from '../../../design-system';
import ArrowRightUpLineIcon from '../../../design-system/Icons/ArrowRightUpLineIcon';

const DiviBotMessageSourcesBlock = ({ toolsArray }) => {
    const sources = toolsArray.reduce((acc, [_, tool]) => {
        const currentToolSources =
            tool.tool_output?.sources?.map((source) => ({
                ...source,
                icon_color: tool.icon_color,
                tool_type: tool.type,
            })) || [];
        acc.push(...currentToolSources);
        return acc;
    }, []);

    const areSources = !!sources.length;

    if (!areSources) {
        return <></>;
    }

    const handleSourceBadgeClick = ({ link: { type, id }, object_id }) => {
        let url = SOURCE_LINK_PREFIX[type] + id;

        if (type === SOURCE_LINK_TYPE.knowledge && object_id) {
            url = url + `?entry=${object_id}`;
        }

        window.open(url, '_blank');
    };

    return (
        <div>
            <p className="font-body-bold text-body-bold-xs text-neutral-300 uppercase mb-2">
                Sources:
            </p>

            <ul className="flex items-center gap-2 flex-wrap">
                {sources.map((source, index) => {
                    const { label, icon_color, tool_type } = source;
                    const badgeColor = applyOpacityToHex(
                        icon_color,
                        TOOL_BADGE_OPACITY[tool_type] || 100
                    );

                    return (
                        <li key={index} className="max-w-full">
                            <Badge
                                text={label}
                                color={badgeColor}
                                trailingIcon={ArrowRightUpLineIcon}
                                trailingIconColor="#5E6470"
                                onBadgeClick={() => handleSourceBadgeClick(source)}
                            />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default memo(DiviBotMessageSourcesBlock);
