import React, { memo } from 'react';

import { applyOpacityToHex } from '../../../helpers/applyOpacityToHex';
import { TOOL_BADGE_OPACITY, TOOL_ICON, TOOL_ICON_COLOR } from '../../../constants/assistant';

import { Badge } from '../../../design-system';
import PlayProcessIcon from '../../../design-system/Icons/PlayProcessIcon';

const DiviBotMessageHeader = ({ toolsArray }) => {
    const areToolsSelected = !!toolsArray.length;

    const uniqueByType = (arr) => {
        if (!areToolsSelected) return [];

        const typesMap = {};
        return arr.filter(([_, { type }]) => !typesMap[type] && (typesMap[type] = true));
    };

    const formattedToolsArray = uniqueByType(toolsArray);

    return (
        <div className="flex items-start min-h-[24px] flex-wrap gap-2">
            <p className="font-body-bold text-body-bold-xs text-neutral-300 uppercase min-h-[24px] flex items-center">
                DiviBot
            </p>

            {areToolsSelected && (
                <ul className="flex-1 flex items-center gap-2 max-w-full flex-wrap">
                    {formattedToolsArray.map(([toolId, { type, display_name, icon_color }]) => {
                        const badgeColor = applyOpacityToHex(
                            icon_color,
                            TOOL_BADGE_OPACITY[type] || 100
                        );

                        return (
                            <li key={toolId} className="max-w-full">
                                <Badge
                                    color={badgeColor}
                                    text={display_name}
                                    leadingIcon={TOOL_ICON[type] || PlayProcessIcon}
                                    leadingIconColor={TOOL_ICON_COLOR[type] || icon_color}
                                />
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};

export default memo(DiviBotMessageHeader);
