import React, { useEffect, useRef } from 'react';
import {
    markdownShortcutPlugin,
    headingsPlugin,
    listsPlugin,
    quotePlugin,
    thematicBreakPlugin,
    toolbarPlugin,
    MDXEditor,
    codeBlockPlugin,
    frontmatterPlugin,
    linkPlugin,
    linkDialogPlugin,
    tablePlugin,
    diffSourcePlugin,
} from '@mdxeditor/editor';

import '@mdxeditor/editor/style.css';
import '../newRichTextArea.css';

import classNames from 'classnames';

import NewRichTextAreaToolbar from '../NewRichTextAreaToolbar/NewRichTextAreaToolbar';
import { catchAllPlugin } from '../plugins/catchAllPlugin';
import { PlainTextCodeEditorDescriptor } from '../plugins/codeEditorPlugin';

const NewRichTextEditor = ({
    value,
    setValue,
    state,
    placeholder,
    minHeight,
    autoExpand,
    withDeleteButton,
    handleDelete,
    darkBorderColor,
    onBlur,
    isMobileViewActive,
}) => {
    const ref = useRef(null);

    useEffect(() => {
        if (!ref.current) return;

        const displayedValue = ref.current.getMarkdown();
        if (displayedValue === value) return;

        ref.current.setMarkdown(value);
    }, [value]);

    const containerClassName = classNames(
        'border-1 rounded-2',
        {
            'border-neutral-200': state !== 'error' && !darkBorderColor,
            'border-neutral-300': state !== 'error' && darkBorderColor,
            'border-red-500 error-state': state === 'error',
            'bg-white': state !== 'disabled',
            'bg-neutral-50': state === 'disabled',
            'flex-grow': !autoExpand,
        },
        autoExpand ? 'auto-expand' : 'no-auto-expand',
        darkBorderColor && 'dark-border'
    );

    const style = { minHeight: `${minHeight}px` };
    if (!autoExpand) {
        style.height = `${minHeight}px`;
    }

    return (
        <div className={containerClassName} style={style}>
            <MDXEditor
                ref={ref}
                markdown={value ?? ''}
                plugins={[
                    headingsPlugin({ allowedHeadingLevels: [1, 2, 3, 4, 5, 6] }),
                    listsPlugin(),
                    quotePlugin(),
                    thematicBreakPlugin(),
                    codeBlockPlugin({
                        defaultCodeBlockLanguage: '',
                        codeBlockEditorDescriptors: [PlainTextCodeEditorDescriptor],
                    }),
                    frontmatterPlugin(),
                    toolbarPlugin({
                        toolbarContents: () => (
                            <NewRichTextAreaToolbar
                                handleDelete={handleDelete}
                                withDeleteButton={withDeleteButton}
                                isMobileViewActive={isMobileViewActive}
                            />
                        ),
                    }),
                    linkPlugin({ disableAutoLink: true }),
                    linkDialogPlugin({ disableAutoLink: true }),
                    tablePlugin(),
                    diffSourcePlugin({ viewMode: 'rich-text', diffMarkdown: 'boo' }),
                    catchAllPlugin(),
                    markdownShortcutPlugin(),
                ]}
                onChange={state === 'disabled' ? () => {} : setValue}
                readOnly={state === 'disabled'}
                placeholder={placeholder}
                onBlur={onBlur}
                suppressHtmlProcessing={true}
            />
        </div>
    );
};

export default NewRichTextEditor;
